$(document).ready(function(){

    $('#default').change(function(){
        if ($(this).is(':checked') ) {
            $('#hel_gestdocbundle_file_adherents option').css("display", "block");
        }
    });

    $('#active').change(function(){
        if ($(this).is(':checked') ) {
            $('#hel_gestdocbundle_file_adherents option[data-active="1"]').css("display", "block");
            $('#hel_gestdocbundle_file_adherents option[data-active="0"]').css("display", "none");
        }
    });

    $('#desactivate').change(function(){
        if ($(this).is(':checked') ) {
            $('#hel_gestdocbundle_file_adherents option[data-active="0"]').css("display", "block");
            $('#hel_gestdocbundle_file_adherents option[data-active="1"]').css("display", "none");
        }
    });

    $(document).on("keypress", 'form', function (e) {
        var code = e.keyCode || e.which;
        if (code == 13) {
            e.preventDefault();
            return false;
        }
    });

    //Ajout d'un saut de ligne dans les textarea via la touche entrée
    $('input[type=text], textarea').on("keypress", function (e) {
        var code = e.keyCode || e.which;
        if (code == 13) {
            var content = $(this).val();
            $(this).val(content+"\n");
        }
    });

    $('.clear-date').click(function (e){
        e.preventDefault();
        var parent  = $(this).parents('.input-group');
        parent.find('input').val('');
    });

    var container = $('.dynamic-collection');

    container.each(function(){
        this.nbIndex = $(this).find(':input').length;
        this.formatCollection = $(this).attr('data-format');
        if(this.nbIndex  == 0){
            addCollection($(this));
        }else{
            var collection = $(this) ;
            var protos =$(this).find('div.row') ;
            protos.each(function (){
                addDeleteLink($(this),collection);
            })
        }
        addButton($(this));
        $(this).prop('nbIndex', this.nbIndex);
    });

    $('.add_dynamic_collection').click(function(e){
        var parent = $(this).parent('.dynamic-collection');
        addCollection(parent);
        e.preventDefault(); // évite qu'un # apparaisse dans l'URL
        return false;
    });
    function addButton(container){
        container.append('<a href="" class="add_dynamic_collection btn btn-primary">Ajouter</a>');
    }

    function addCollection(container){
        var nbIndex = container.prop('nbIndex');
        nbIndex++;
        var formatCollection = container.prop('formatCollection');
        var prototype = "";

        if(container.attr('id') == "hel_ccpscbundle_infosechagedepaillage_mesuresPoids"){
            prototype = $(container.attr('data-prototype').replace(/__name__label__/g, "Big Bag n°<span class='nb'>" + (nbIndex) + "</span>")
                .replace(/__name__/g, nbIndex));
        }
        else if(container.attr('id') == "hel_ccpscbundle_infosechagedepaillage_mesureSechages"){
            prototype = $(container.attr('data-prototype').replace(/__name__label__/g, 'Groupe de séchage n°' + (nbIndex))
                .replace(/__name__/g, nbIndex));
        }
        else if (container.attr('id') == "hel_ccpscbundle_infosechagedepaillage_mesureSechages"){
            prototype = $(container.attr('data-prototype').replace(/__name__label__/g, 'Big Bag n°' + (nbIndex))
                .replace(/__name__/g, nbIndex));
        }
        else if (container.attr('id') == "hel_ccpscbundle_infomicronettoyage_pureteSpecifiqueSemenceAutresPlantes"){
            prototype = $(container.attr('data-prototype').replace(/__name__label__/g, '')
                .replace(/__name__/g, nbIndex));
        }
        else {
             prototype = $(container.attr('data-prototype').replace(/__name__label__/g, 'Mesure n°' + (nbIndex))
                .replace(/__name__/g, nbIndex));
        }
        var subcontainer = container.find(formatCollection+':first');
        if(subcontainer.length <= 0){container.append('<'+formatCollection+'></'+formatCollection+'>');}

        var array_micronettoyage = [
            'hel_ccpscbundle_infomicronettoyage_autreImpurteGrosElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteBonNsElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteEntre2GrillesElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteTresPetitElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteAspiElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteDechetsElements',
            'hel_ccpscbundle_infomicronettoyage_autreImpurteBonElements'
       ];

        if($.inArray(container.attr('id'), array_micronettoyage) > -1){
            container.find('.add_dynamic_collection').before(prototype);

        }else{
            container.find(formatCollection+':first').append(prototype);
        }


        container.prop('nbIndex', nbIndex);
        addDeleteLink(prototype, container);
    }


    function addDeleteLink(prototype, container) {
        var nbIndex = container.prop('nbIndex');
        var formatCollection = container.prop('formatCollection');


        if(formatCollection == 'table'){
            if (prototype[0].className == "mesure-germination") {
                var deleteLink = $('<td><a href="#" style="display:none" class="rmv_dynamic_collection btn btn-sm btn-danger"><i class="glyphicon glyphicon-remove"></i></a></td>');
            } else {
                var deleteLink = $('<td><a href="#" class="rmv_dynamic_collection btn btn-sm btn-danger"><i class="glyphicon glyphicon-remove"></i></a></td>');
            }
       }else{
            var deleteLink = $('<a href="#" class="rmv_dynamic_collection pull-right btn btn-sm btn-danger"><i class="glyphicon glyphicon-remove"></i></a>');
        }
        prototype.append(deleteLink);

        deleteLink.click(function(e){
            prototype.remove();
            nbIndex--;
            if( container.prop('nbIndex')){
                container.prop('nbIndex',nbIndex);
            }
            // prototype.remove();
            checkNbIndex();
            
            // e.preventDefault(); // évite qu'un # apparaisse dans l'URL
            // prototype.remove();
            // nbIndex--;
            // container.prop('nbIndex',nbIndex);
        });

        // $('.rmv_dynamic_collection').click(function(e) {
        //     $(this).parent('.form-group').remove();
        //     e.preventDefault(); // évite qu'un # apparaisse dans l'URL
        //     // nbIndex--;
        //     container.prop('nbIndex',nbIndex);
        //     checkNbIndex();
        //     //return false;
        // });
    }

    function checkNbIndex(formatCollection){
        var form_groups = $('.dynamic-collection > '+formatCollection);
        $.each(form_groups, function(index, element){
            var ex_val = $(element).find('.nb').html();
            var id = (index + 1).toString();
            $(this).html($(this).html().replace(ex_val, id));

            // element.replace(ex_val, id);
        });
    }

    $('.actionAffiche').click(function () {
        $('.poids').toggleClass('ntic-masque ntic-affiche')
    })
});



